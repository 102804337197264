/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

$primary: #3886c3;
$secondary: #ed1d91;
$info: #e5e7eb;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

:root {
  --fh-font-family: "Montserrat";
  --fh-primary-color: #3886c3;
  --fh-secondary-color: #ed1d91;
  --fh-info-color: #e5e7eb;
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";

body,
textarea,
input,
select {
  font-family: var(--fh-font-family);
  line-height: 22px;
  font-size: 16px;
}

input,
select {
  border: 1px solid #818a91;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: white;
  color: black;
  margin: 0px;
}

textarea {
  border: 1px solid #818a91;
  border-radius: 10px;
  padding: 10px;
  background-color: white;
  color: black;
}

input[type="text"],
input[type="tel"],
input[type="date"],
input[type="number"],
select {
  min-width: 200px;
}

input[type="checkbox"] {
  height: 16px;
  width: 16px;
}

textarea {
  border-radius: 10px;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.pointer {
  cursor: pointer;
}

.fh-form-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.fh-form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  justify-items: center;
}

.fh-form-grid .fh-form-input-field {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: center;
  width: 100%;
}
//maps-style---------------------------
.gm-style-iw-chr {
  max-height: 10px !important;
}
.gm-ui-hover-effect {
  z-index: 100 !important;
}
.gm-style-iw {
  max-width: 25rem !important;
  min-width: 23rem !important;
}

.hrMail {
  border: solid 1px black;
  opacity: 1;
}

//textEditor------------------------------
//DEFAULT OPTION FOR TEXT EDITOR
.ql-editor {
  font-family: 'Verdana', sans-serif;
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="josefin-sans"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="josefin-sans"]::before {
  content: 'Josefin Sans';
  font-family: 'Josefin Sans', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="verdana"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before {
  content: 'Verdana';
  font-family: 'Verdana', sans-serif;
}

.ql-snow .ql-picker.ql-size .ql-picker-item [data-value="false"]::before {
  content: 'Normal';
  font-size: 16px !important;
}

.ql-font-josefin-sans {
  font-family: 'Josefin Sans', sans-serif;
}

.ql-snow .ql-picker.ql-hr::before {
  content: "\\2015"; /* Carattere Unicode per una linea orizzontale, per toolbar button */
}

.fh-separator {
  opacity: 1 !important;
}

//NGB TYPEAHEAD CSS
ngb-typeahead-window.dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}
